@tailwind components;

@layer components {

  /*===============
   * 
   * Default theme
   *
   =================*/

  /* Skeleton loader */
  .skeleton {
    @apply bg-gradient-to-r from-neutral-100 to-white;
  }

  /* General */
  .content {
    @apply my-2 md:my-5;
  }

  .text-light {
    @apply text-gray-400;
  }

  /* Backgrounds */
  .bg-page {
    @apply bg-zinc-100;
  }

  .bg-over {
    @apply bg-white;
  }

  .bg-over-hover {
    @apply bg-gray-50 bg-opacity-50;
  }

  /* Links */
  .link {
    @apply text-sky-600 hover:text-sky-700;
  }

  .nav-link {
    @apply text-sm text-gray-600 hover:text-gray-900;
  }

  .nav-link.active {
    @apply text-gray-700 font-semibold;
  }

  .nav-link .chevron {
    @apply text-gray-400 text-opacity-70;
  }

  /* Tabs */
  .tab {
    @apply inline-flex p-4 rounded-t-lg border-b-2 border-transparent hover:text-gray-600 hover:border-gray-300;
  }

  .tab.active {
    @apply text-blue-600 border-blue-600;
  }

  /* Tags */
  .tag.instantiate {
    @apply border border-amber-300 bg-amber-100 text-amber-600;
  }

  .tag.call {
    @apply border border-purple-300 bg-purple-100 text-purple-600;
  }

  .tag.store {
    @apply border border-green-300 bg-green-100 text-green-600;
  }

  .tag.upgrade {
    @apply border border-cyan-300 bg-cyan-100 text-cyan-600;
  }

  .tag.terminate {
    @apply border border-zinc-400 bg-zinc-200 text-zinc-700;
  }

  .tag.chain {
    @apply bg-emerald-100 text-emerald-800;
  }

  .tag.contract {
    @apply text-neutral-600 bg-neutral-200
  }

  .tag.eoa {
    @apply text-orange-600 bg-orange-200
  }

  .tag.wasm {
    @apply text-lime-600 bg-lime-200
  }

  .tag.upgraded {
    @apply bg-cyan-200 text-cyan-600;
  }

  .tag.removed {
    @apply bg-red-600 text-red-200;
  }

  .tag.terminated {
    @apply bg-zinc-600 text-zinc-200;
  }

  /* Forms & Controls */ 
  .input {
    @apply appearance-none block py-3 px-4 text-sm text-gray-900 border border-gray-200 focus:outline-none focus:border-blue-400
  }

  .select.inline {
    @apply appearance-none block text-sm text-gray-900 focus:outline-none
  }

  .select-option.active {
    @apply bg-gray-100 text-gray-900
  }

  .select-option .selected {
    @apply text-gray-500
  }

  .search {
    @apply text-gray-400;
  }

  /* Buttons */
  .btn {
    @apply py-2 px-4
  }

  .btn.btn-primary {
    @apply bg-blue-500 text-white hover:bg-blue-700 focus:outline-none
  }

  .btn.btn-secondary {
    @apply text-sky-600 hover:text-sky-700 focus:outline-none
  }
}